.testimonial {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .testimonial-image {
    margin-right: 20px;
  }
  
  .testimonial-image img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .testimonial-content {
    flex: 1;
  }
  
  .testimonial-quote {
    font-size: 1.2rem;
    font-style: italic;
    margin-bottom: 10px;
  }
  
  .testimonial-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .testimonial-title {
    font-size: 1rem;
    font-style: italic;
    color: #999;
  }
  
  .dark-placeholder::placeholder {
    color: rgb(114, 110, 110); /* or any color you want for dark mode */
  }
  