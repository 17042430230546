/* ConfirmAlertStyles.css */
.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.5);
}

.react-confirm-alert-body {
    border-radius: 10px;
    padding: 20px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 1.2em;
    font-family: nokora;
    font-weight: bold;
}

.react-confirm-alert-button-group > button {
    padding: 10px 20px;
    border: none;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.react-confirm-alert-button-group > button:nth-child(1) {
    background-color: #e74c3c; /* red for "Yes" */
    color: white;
}

.react-confirm-alert-button-group > button:nth-child(1):hover {
    background-color: #c0392b;
}

.react-confirm-alert-button-group > button:nth-child(2) {
    background-color: #2ecc71; /* green for "No" */
    color: white;
}

.react-confirm-alert-button-group > button:nth-child(2):hover {
    background-color: #27ae60;
}
