@font-face {
    font-family: nida;
    src: url(../../public/NiDA\ Bayon\ Regular.ttf);
}

.nida {
    font-family: nida;
}

@font-face {
    font-family: nokoraBold;
    src: url(../../public/Nokora-Bold.ttf);
}

.nokoraBold {
    font-family: nokoraBold;
}

@font-face {
    font-family: nokora;
    src: url(../../public/Nokora-Regular.ttf);
}

.nokora {
    font-family: nokora;
}

@font-face {
    font-family: mono;
    src: url(../../public/monospace-821-bold-bt.ttf);
}

.mono {
    font-family: mono;
}

@font-face {
    font-family: monoRoman;
    src: url(../../public/Monospac821-BT-Roman.ttf);
}

.monoRoman {
    font-family: monoRoman;
}

html {
    scroll-behavior: smooth;
}